.splashScreen{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  margin: 108px auto
}


.ldsDualRing {
  display: inline-block;
  width: 80px;
  height: 80px;
  margin-bottom: 40px;
}

.ldsDualRing:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #000000 transparent #000000 transparent;
  animation: ldsDualRing 1.2s linear infinite;
}

@keyframes ldsDualRing {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}